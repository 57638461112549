const primary = {
  main: '#8751FF',
};

const secondary = {
  main: '#666666',
};

const red = {
  main: '#D70028',
};

const green = {
  main: '#009F4D',
};

export const palette = {
  primary,
  secondary,
  green,
  red,
};
